<template>
  <v-container fluid>
    <ProgressCircular :isLoaded="isLoaded" />
    <v-row v-if="!editCard" justify="space-between">
      <v-col cols="12" sm="8" md="6">
        <h3 class="mt-6 mb-1">Основні дані</h3>
        <v-avatar v-if="user.avatar" class="mb-3" size="100">
          <img :src="`${baseURL}/${user.avatar}`" alt="logo" />
        </v-avatar>
        <div>
          <span>ID:</span>
          <span class="font-weight-medium ml-2">{{ user.id }}</span>
        </div>
        <div>
          <span>Дата реєстрації:</span>
          <span class="font-weight-medium ml-2">{{ user.created_at | getShortDate }}</span>
        </div>
        <div>
          <span>Імʼя:</span>
          <span class="font-weight-medium ml-2">{{ user.first_name }}</span>
        </div>
        <div>
          <span>Прізвище:</span>
          <span class="font-weight-medium ml-2">{{ user.last_name }}</span>
        </div>
        <div>
          <span>Телефон:</span>
          <span class="font-weight-medium ml-2">{{ formatPhoneNumber(user.phone) }}</span>
        </div>
        <div>
          <span>Telegram:</span>
          <span class="font-weight-medium ml-2">{{ usersTelegram && formatPhoneNumber(usersTelegram) }}</span>
        </div>
        <div>
          <span>Viber:</span>
          <span class="font-weight-medium ml-2">{{ usersViber && formatPhoneNumber(usersViber) }}</span>
        </div>
        <div>
          <span>WhatsApp:</span>
          <span class="font-weight-medium ml-2">{{ usersWhatsApp && formatPhoneNumber(usersWhatsApp) }}</span>
        </div>
        <div>
          <span>Email:</span>
          <span class="font-weight-medium ml-2">{{ user.email }}</span>
        </div>
        <div>
          <span>Дата народження:</span>
          <span class="font-weight-medium ml-2">{{ user.birthday | getShortDate }}</span>
        </div>
        <div>
          <span>Мова:</span>
          <span class="font-weight-medium ml-2">{{ user.locale }}</span>
        </div>
        <div v-if="employers.length">
          <span>Модель телефону:</span>
          <span class="font-weight-medium ml-2">{{ user.phone_model }}</span>
        </div>
        <div v-if="employers.length">
          <span>Операційна система:</span>
          <span class="font-weight-medium ml-2">{{ user.operating_system }}</span>
        </div>
        <div v-if="employers.length">
          <span>Версія додатку:</span>
          <span class="font-weight-medium ml-2">{{ user.app_version }}</span>
        </div>
        <PurchaseTable v-if="!isProfilePage" :purchase-data="purchaseData" />
      </v-col>
      <v-col cols="12" sm="8" md="6">
        <RolesTable v-if="employers && employers.length" :employers="employers" />
      </v-col>
    </v-row>

    <v-row v-else justify="space-between">
      <v-col cols="12" sm="8" md="6">
        <h3 class="mt-6 mb-1">Основні дані</h3>
        <v-avatar
          v-if="user.avatar"
          class="mb-3"
          size="100"
          @mouseenter="showRemoveIcon = true"
          @mouseleave="showRemoveAvatarModal ? (showRemoveIcon = true) : (showRemoveIcon = false)"
        >
          <img :src="`${baseURL}/${user.avatar}`" alt="logo" />
          <v-icon v-if="showRemoveIcon" class="remove-icon" large @click="showRemoveAvatarModal = true">
            mdi-delete-forever-outline
          </v-icon>
        </v-avatar>
        <FileUploader
          :actionType="isProfilePage ? 'uploadProfileLogo' : 'uploadUserLogo'"
          :userId="user.id"
          accept="image/*"
          label="Виберіть аватар"
          prepend-icon="mdi-camera"
          @updateUpload="initialize"
        />
        <div>
          <span>ID:</span>
          <span class="font-weight-medium ml-2">{{ user.id }}</span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Ім'я:</span>
          <v-text-field
            v-model="editedItem.first_name"
            :error-messages="firstNameErrors"
            class="ml-2"
            dense
            @blur="$v.editedItem.first_name.$touch()"
            @input="$v.editedItem.first_name.$touch()"
          ></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Прізвище:</span>
          <v-text-field
            v-model="editedItem.last_name"
            :error-messages="lastNameErrors"
            class="ml-2"
            dense
            @blur="$v.editedItem.last_name.$touch()"
            @input="$v.editedItem.last_name.$touch()"
          ></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Телефон:</span>
          <PhoneEdit class="ml-2" :value="editedItem.phone" disabled />
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Telegram:</span>
          <PhoneEdit class="ml-2" :value="usersTelegram" @change="(val) => (usersTelegram = val)" />
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Viber:</span>
          <PhoneEdit class="ml-2" :value="usersViber" @change="(val) => (usersViber = val)" />
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>WhatsApp:</span>
          <PhoneEdit class="ml-2" :value="usersWhatsApp" @change="(val) => (usersWhatsApp = val)" />
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Email:</span>
          <v-text-field v-model="editedItem.email" class="ml-2" dense></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <v-menu transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="getShortBirthday"
                label="Дата народження"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editedItem.birthday"
              :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10)"
              min="1924-01-01"
              dateFormat="dd-MM-yyyy HH:mm:ss"
              ok-text="так"
              @change="(val) => (editedItem.birthday = `${val} 00:00:00`)"
            />
          </v-menu>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Мова:</span>
          <v-select
            v-model="editedItem.locale"
            :error-messages="localeErrors"
            :items="localesDictionary"
            class="ml-2"
            dense
            item-text="description"
            item-value="value"
            @blur="$v.editedItem.locale.$touch()"
            @input="$v.editedItem.locale.$touch()"
          >
          </v-select>
        </div>
      </v-col>
      <v-col v-if="!isProfilePage" cols="12" sm="8" md="6">
        <div class="d-flex align-baseline custom-field">
          <span>Модель телефону:</span>
          <v-text-field v-model="editedItem.phone_model" class="ml-2" dense></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Операційна система:</span>
          <v-text-field v-model="editedItem.operating_system" class="ml-2" dense></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span>Версія додатку:</span>
          <v-text-field v-model="editedItem.app_version" class="ml-2" dense></v-text-field>
        </div>
      </v-col>
    </v-row>

    <ConfirmActionModal :showModal="showRemoveAvatarModal" @closeModal="showRemoveAvatarModal = false">
      <template v-slot:title>Видалити лого?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn color="primary" text @click="removeAvatar">Так</v-btn>
        <v-btn text @click="showRemoveAvatarModal = false">Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import cloneDeep from '@/mixins/cloneDeep'
import convertAddress from '@/mixins/convertAddress'
import convertUserStatusesTypes from '@/mixins/convertStatusesTypes'
import convertRolesTypes from '@/mixins/convertRolesTypes'
import { validationMixin } from 'vuelidate'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import convertDate from '@/mixins/convertDate'
import PurchaseTable from '@/components/support/users/PurchaseTable'
import RolesTable from '@/components/support/users/RolesTable'
import PhoneEdit from '@/components/common/PhoneEdit'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'

export default {
  name: 'UserGeneralInfo',

  mixins: [validationMixin, cloneDeep, convertAddress, convertUserStatusesTypes, convertRolesTypes, convertDate],

  validations() {
    return {
      editedItem: {
        first_name: { required },
        last_name: { required },
        phone: { required, minLength: minLength(13), maxLength: maxLength(13) },
        locale: { required },
      },
    }
  },

  components: {
    PhoneEdit,
    RolesTable,
    PurchaseTable,
    ProgressCircular: () => import('@/components/common/ProgressCircular'),
    FileUploader: () => import('@/components/common/FileUploader'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
  },

  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    employers: {
      type: Array,
      required: true,
      default: () => [],
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTabName: {
      required: true,
      default: null,
    },
    isLoaded: {
      type: Boolean,
      required: true,
      default: false,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    editedItem: {},
    editedAddress: {},
    showRemoveIcon: false,
    showRemoveAvatarModal: false,
    purchaseData: [],
  }),

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('dictionaries', ['localesDictionary']),
    ...mapState('admin', ['usersPurchase']),
    ...mapState('users', ['client']),

    currentCompanyId() {
      return this.$route.params.id
    },

    isProfilePage() {
      return this.$route.name === 'Profile'
    },

    firstNameErrors() {
      const errors = []
      if (!this.$v.editedItem.first_name.$dirty) return errors
      !this.$v.editedItem.first_name.required && errors.push('Це поле обов"язкове')
      return errors
    },

    lastNameErrors() {
      const errors = []
      if (!this.$v.editedItem.last_name.$dirty) return errors
      !this.$v.editedItem.last_name.required && errors.push('Це поле обов"язкове')
      return errors
    },

    localeErrors() {
      const errors = []
      if (!this.$v.editedItem.locale.$dirty) return errors
      !this.$v.editedItem.locale.required && errors.push('Це поле обов"язкове')
      return errors
    },

    getShortBirthday() {
      const date = new Date(this.editedItem.birthday)
      const d = new Date(date)
      const day = ('0' + d.getDate()).slice(-2)
      const month = ('0' + (d.getMonth() + 1)).slice(-2)
      const year = d.getFullYear()
      return `${day}.${month}.${year}`
    },

    usersTelegram: {
      get: function () {
        return this.editedItem.contacts?.personal
          ? this.editedItem.contacts.personal[0]?.contact_type?.find((item) => item.type === 'telegram_channel')?.value || null
          : null
      },
      set: function (val) {
        this.setContact('telegram_channel', val)
      },
    },

    usersViber: {
      get: function () {
        return this.editedItem.contacts?.personal
          ? this.editedItem.contacts.personal[0]?.contact_type?.find((item) => item.type === 'viber')?.value || null
          : null
      },
      set: function (val) {
        this.setContact('viber', val)
      },
    },

    usersWhatsApp: {
      get: function () {
        return this.editedItem.contacts?.personal
          ? this.editedItem.contacts.personal[0]?.contact_type?.find((item) => item.type === 'whatsapp')?.value || null
          : null
      },
      set: function (val) {
        this.setContact('whatsapp', val)
      },
    },
  },

  watch: {
    editCard(val) {
      if (!val) {
        this.initialize()
      }
    },
    isDataSaved(val) {
      if (val && this.currentTabName === 'info') {
        this.$v.editedItem.$touch()
        if (!this.$v.$anyError) this.$emit('saveData', this.editedItem)
      }
    },
    showRemoveAvatarModal(val) {
      if (!val) this.showRemoveIcon = false
    },
  },

  methods: {
    formatPhoneNumber,
    ...mapActions('profile', ['deleteProfileLogo']),
    ...mapActions('admin', ['deleteUserLogo', 'loadUsersPurchase']),
    ...mapActions('users', ['loadSelectedClient']),

    async initialize() {
      this.editedItem = this.cloneObjectDeep(this.user)
      if (this.isProfilePage) return
      try {
        if (this.isAdmin) {
          await this.loadUsersPurchase({ userId: this.user.id })
          this.purchaseData = [...this.usersPurchase]
        } else {
          await this.loadSelectedClient({ companyId: this.currentCompanyId, userId: this.user.id })
          this.purchaseData = [{ ...this.client, companyId: +this.currentCompanyId }]
        }
      } catch (e) {
        this.purchaseData = []
      }
    },

    async removeAvatar() {
      this.showRemoveAvatarModal = false
      if (this.isProfilePage) {
        await this.deleteProfileLogo()
      } else {
        const payload = { userId: this.user.id }
        await this.deleteUserLogo(payload)
      }
      await this.initialize()
    },

    setContact(type, val) {
      if (!this.editedItem.contacts) this.editedItem.contacts = {}
      if (!this.editedItem.contacts.personal) this.editedItem.contacts.personal = []
      if (!this.editedItem.contacts.personal.length) {
        this.editedItem.contacts.personal.push({ contact_type: [{ type, value: val }] })
      }
      let contact_type = this.editedItem.contacts.personal[0].contact_type
      let contactIndex = contact_type.findIndex((item) => item.type === type)
      if (contactIndex > -1) {
        contact_type[contactIndex] = { type, value: val }
      } else {
        contact_type.push({ type, value: val })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vars';

.custom-field {
  max-width: 500px !important;
}

.remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  color: $red;
  background-color: $card-bg;
  cursor: pointer;
}
</style>
